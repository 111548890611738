import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SearchAddressComponent } from './components/search-address/search-address.component';
import { RouterModule, Routes } from '@angular/router';
import {AddressGuard} from "./address.guard";

const routes: Routes = [
    {
        path: '',
        component: SearchAddressComponent,
        canActivate: [AddressGuard],
    },
];

@NgModule({
    declarations: [SearchAddressComponent],
    exports: [SearchAddressComponent],
    imports: [SharedModule, RouterModule.forChild(routes)],
    providers: []
})
export class AddressModule {}
