import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { InitService } from './core/services/init.service';
import { PlatformService } from './core/services/platform.service';
import { Observable } from 'rxjs';
import { NotificationSelectors } from './module/notification/store/notification.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from './store';
import { DialogController } from './page-modules/messenger/dialog.controller';
import { AuthenticationService } from './core/auth/authentication.service';
import { first } from 'rxjs/operators';
import { UserTypeEnum } from './core/user/enums/user-type.enum';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AccountService } from './core/account/account.service';
import {ClearSWService} from "./core/services/clear-sw.service";
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    showMenu = false;
    unreadCount$: Observable<number>;
    unreadNotifyExist$ = this.store.select(NotificationSelectors.unreadExist);

    auth = false;

    constructor(
        public platformService: PlatformService,
        private initService: InitService,
        private readonly store: Store<IAppState>,
        private readonly dialogController: DialogController,
        private authenticationService: AuthenticationService,
        private navCtrl: NavController,
        private router: Router,
        private accountService: AccountService,
        private clearSWService: ClearSWService,
    ) {
        this.initializeApp();
        this.platformService.showMenu().subscribe((ev) => {
            this.showMenu = ev;
        });
        this.authenticationService.watchAuthState$().subscribe(async (auth) => {
            this.auth = !!auth;
        });
    }

    async initializeApp() {
        await this.platformService.getPlatform().ready();
        this.clearSWService.clear();
        this.initService.startWithAuth();
        this.initService.startNonAuth()
        this.unreadCount$ = this.dialogController.unreadCount$();
    }

    async goTaskAdd() {
        try {
            const authorized = await this.authenticationService.isAuthorized().toPromise();
            if (!authorized) {
                return this.navCtrl.navigateForward('/task-add', { state: { returnUrl: this.router.url } });
            }
            if (authorized) {
                const user = await this.accountService.getProfileInfo().pipe(first()).toPromise();
                if (user.type === UserTypeEnum.organisation) {
                    return this.navCtrl.navigateForward('/task-add/type', { state: { returnUrl: this.router.url } });
                } else {
                    return this.navCtrl.navigateForward('/task-add', { state: { returnUrl: this.router.url } });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
}
