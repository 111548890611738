import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';

import { ButtonComponent } from './ui/button/button.component';
import { RadioComponent } from './ui/radio/radio.component';
import { ChipComponent } from './ui/chip/chip.component';
import { SvgIconComponent } from './ui/svg-icon/svg-icon.component';
import { MaskDirective } from './directives/mask.directive';
import { InputCurrencyComponent } from './ui/input-currency/input-currency.component';
import { InputPhoneComponent } from './ui/input-phone/input-phone.component';
import { InputTextareaComponent } from './ui/input-textarea/input-textarea.component';
import { InputTextComponent } from './ui/input-text/input-text.component';
import { InputNumberComponent } from './ui/input-number/input-number.component';
import { InputDateComponent } from './ui/input-date/input-date.component';
import { InputReadonlyComponent } from './ui/input-readonly/input-readonly.component';
import { SzInputComponent } from './ui/sz-input/sz-input.component';
import { BackButtonComponent } from './ui/back-button/back-button.component';
import { HeaderComponent } from './ui/header/header.component';
import { ImageComponent } from './widgets/image/image.component';
import { InputCodeComponent } from './ui/input-code/input-code.component';
import { AdItemComponent } from './ui/ad-item/ad-item.component';
import { AdItemSkeletonComponent } from './ui/ad-item-skeleton/ad-item-skeleton.component';
import { AdStatusComponent } from './ui/ad-status/ad-status.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { RefresherComponent } from './ui/refresher/refresher.component';
import { InfiniteScrollComponent } from './ui/infinite-scroll/infinite-scroll.component';
import { SelfWorkerItemComponent } from './ui/self-worker-item/self-worker-item.component';

import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { StatusNamePipe } from './pipes/status-name.pipe';
import { ReviewTextPipe } from './pipes/review-text.pipe';

import { MapPreviewWidgetComponent } from './widgets/map-preview-widget/map-preview-widget.component';
import { WidgetUserInfoViewComponent } from './widgets/widget-user-info-view/widget-user-info-view.component';

import { UiCatMapIconComponent } from './ui/ui-cat-map-icon/ui-cat-map-icon.component';
import { UiAdBudgetComponent } from './ui/ui-ad-budget/ui-ad-budget.component';
import { UiSegmentComponent } from './ui/ui-segment/ui-segment.component';
import { UiLabeldTextViewComponent } from './ui/ui-labeld-text-view/ui-labeld-text-view.component';
import { UiDividerComponent } from './ui/ui-divider/ui-divider.component';
import { UiOnlineStatusComponent } from './ui/ui-online-status/ui-online-status.component';
import { UiImagePreviewComponent } from './ui/ui-image-preview/ui-image-preview.component';
import { UiImageSelectComponent } from './ui/ui-image-select/ui-image-select.component';
import { UiAvatarComponent } from './ui/ui-avatar/ui-avatar.component';
import { UiHeaderUserInfoComponent } from './ui/ui-header-user-info/ui-header-user-info.component';
import { NoDataComponent } from './containers/no-data/no-data.component';
import { InputOneWordComponent } from './ui/input-one-word/input-one-word.component';
import { RatingComponent } from './ui/rating/rating.component';
import { AdListComponent } from './widgets/ad-list/ad-list.component';
import { SelfWorkerListComponent } from './widgets/self-worker-list/self-worker-list.component';
import { CategoryIconComponent } from './ui/category-icon/category-icon.component';
import { UiAdLinkWithLabelComponent } from './ui/ui-ad-link-with-label/ui-ad-link-with-label.component';
import { UiReviewComponent } from './ui/ui-review/ui-review.component';
import { UiRatingLineComponent } from './ui/ui-rating-line/ui-rating-line.component';
import { UiRatingStarComponent } from './ui/ui-rating-star/ui-rating-star.component';
import { ReviewCountComponent } from './ui/review-count/review-count.component';
import { UiSwItemStatComponent } from './ui/ui-sw-item-stat/ui-sw-item-stat.component';
import { UiRatingNumberComponent } from './ui/ui-rating-number/ui-rating-number.component';
import { UiIconComponent } from './ui/ui-icon/ui-icon.component';
import { UiRatingSelectComponent } from './ui/ui-rating-select/ui-rating-select.component';
import { CFooterComponent } from './containers/c-footer/c-footer.component';
import { CContainerComponent } from './containers/c-container/c-container.component';
import { CategoryItemComponent } from './ui/category-item/category-item.component';
import { AdTaskInfoComponent } from './ui/ad-task-info/ad-task-info.component';
import { OfferItemComponent } from './ui/offer-item/offer-item.component';
import { UiReviewProfileItemComponent } from './ui/ui-review-profile-item/ui-review-profile-item.component';
import { OfferInfoWidgetComponent } from './widgets/offer-info-widget/offer-info-widget.component';
import { StatusColorPipe } from './pipes/statusColor.pipe';
import { SelfworkerOfferItemComponent } from './ui/selfworker-offer-item/selfworker-offer-item.component';
import { ModalTextEditorComponent } from './ui/modal-text-editor/modal-text-editor.component';
import { AutosizeModule } from 'ngx-autosize';
import { ModalReasonComponent } from './ui/modal-reason/modal-reason.component';
import { QrcodeComponent } from './ui/qrcode/qrcode.component';
import { ButtonItemComponent } from './ui/button-item/button-item.component';
import { CardAlertComponent } from './ui/card-alert/card-alert.component';
import { UiToggleComponent } from './ui/ui-toggle/ui-toggle.component';
import { CustomModalController } from '../core/extends/custom-modal-controller.service';
import { RouterModule } from '@angular/router';
import { ProfileBeExecutorComponent } from './ui/be-executor/be-executor.component';
import { CreateAdBannerComponent } from './ui/create-ad-banner/create-ad-banner.component';
import { DateAdPipe } from './pipes/date-ad.pipe';
import { AppUpdateProgressComponent } from './ui/app-update-progress/app-update-progress.component';
import { UiRangeComponent } from './ui/ui-range/ui-range.component';
import { ItemComponent } from './components/item/item.component';
import { InputCardComponent } from './ui/input-card/input-card.component';
import { AppPopupReviewComponent } from './ui/app-popup-review/app-popup-review.component';
import { AppPopupReviewCompleteComponent } from './ui/app-popup-review-complete/app-popup-review-complete.component';
import { AppPopupReviewModalComponent } from './ui/app-popup-review-modal/app-popup-review-modal.component';
import {AddressModule} from "../modules/address/address.module";

const componentsOld = [
    ButtonComponent,
    RadioComponent,
    ChipComponent,
    SvgIconComponent,
    MaskDirective,
    SzInputComponent,
    InputCurrencyComponent,
    InputPhoneComponent,
    InputTextareaComponent,
    InputTextComponent,
    InputNumberComponent,
    InputReadonlyComponent,
    InputCodeComponent,
    InputCardComponent,
    InputDateComponent,
    InputOneWordComponent,
    BackButtonComponent,
    ImageComponent,
    UiCatMapIconComponent,
    AdItemComponent,
    AdItemSkeletonComponent,
    AdStatusComponent,
    LoadingSpinnerComponent,
    RefresherComponent,
    InfiniteScrollComponent,
    SelfWorkerItemComponent,
];

const widget = [
    MapPreviewWidgetComponent,
    WidgetUserInfoViewComponent,
    AdListComponent,
    SelfWorkerListComponent,
    OfferInfoWidgetComponent,
];

const ui = [
    CategoryIconComponent,
    UiAdBudgetComponent,
    UiSegmentComponent,
    UiLabeldTextViewComponent,
    UiDividerComponent,
    UiOnlineStatusComponent,
    UiImagePreviewComponent,
    UiImageSelectComponent,
    UiAvatarComponent,
    UiHeaderUserInfoComponent,
    RatingComponent,
    UiAdLinkWithLabelComponent,
    UiReviewComponent,
    UiRatingLineComponent,
    UiRatingStarComponent,
    ReviewCountComponent,
    CategoryItemComponent,
    AdTaskInfoComponent,
    OfferItemComponent,
    UiSwItemStatComponent,
    UiRatingNumberComponent,
    UiIconComponent,
    UiRatingSelectComponent,
    ReviewCountComponent,
    CategoryItemComponent,
    UiReviewProfileItemComponent,
    SelfworkerOfferItemComponent,
    QrcodeComponent,
    ButtonItemComponent,
    CardAlertComponent,
    UiToggleComponent,
    ProfileBeExecutorComponent,
    CreateAdBannerComponent,
    AppUpdateProgressComponent,
    UiRangeComponent,
    AppPopupReviewComponent,
    AppPopupReviewCompleteComponent,
];

const containers = [HeaderComponent, NoDataComponent, CFooterComponent, CContainerComponent];

const pipes = [LinkifyPipe, SanitizeHtmlPipe, StatusNamePipe, StatusColorPipe, ReviewTextPipe, DateAdPipe];

const modals = [ModalTextEditorComponent, ModalReasonComponent, AppPopupReviewModalComponent];

const components = [ItemComponent];

const modules = [IonicModule, CommonModule, FormsModule, RouterModule, AngularSvgIconModule, NgxMaskModule];

@NgModule({
    declarations: [...components, ...componentsOld, ...containers, ...pipes, ...widget, ...ui, ...modals],
    exports: [...components, ...componentsOld, ...containers, ...pipes, ...widget, ...ui, ...modals, ...modules],
    imports: [...modules, AngularSvgIconModule.forRoot(), NgxMaskModule.forChild(), AutosizeModule],
    providers: [{ provide: ModalController, useExisting: CustomModalController }]
})
export class SharedModule {}
