<div
    class="body_rubik font_regular"
    [class.disabled]="disabled"
    [innerHTML]="_value && _value.length ? (_value | date: 'd MMMM yyyy') : placeholder"
    [class.placeholder]="!_value || !_value.length"
    id="date-modal"
></div>

<ion-modal trigger="date-modal" [keepContentsMounted]="true" >
  <ng-template>
    <ion-datetime id="datetime" [mode]="'md'" [color]="'primary'" [max]="maxDate" presentation="date" [min]="minDate" [showDefaultButtons]="true" doneText="Выбрать" cancelText="Отмена" [(ngModel)]="_value" firstDayOfWeek="1" (ionChange)="changeDate($event)">
   </ion-datetime>
  </ng-template>
</ion-modal>
