import { createAnimation } from '@ionic/core';

export const modalEnterAnimation: any = (baseEl: any) => {
    const DURATION = 200;
    const root = baseEl.shadowRoot;
    const backdropAnimation = createAnimation().addElement(root.querySelector('ion-backdrop')!);

    const wrapperAnimation = createAnimation()
        .addElement(root.querySelector('.modal-wrapper')!)
        .duration(DURATION)
        .beforeStyles({ transform: 'translateY(0px)' })
        .easing('ease-in')
        .keyframes([
            { offset: 0, opacity: '0' },
            { offset: 0.5, opacity: '0.5' },
            { offset: 1, opacity: '1' },
        ]);

    return createAnimation().addElement(baseEl).addAnimation([backdropAnimation, wrapperAnimation]);
};
