import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { bottomModalEnterAnimation } from './animations/bottom-modal.enter';
import { bottomModalLeaveAnimation } from './animations/bottom-modal.leave';
import { PlatformService } from '../../core/services/platform.service';

@Injectable({
    providedIn: 'root',
})
export class BottomModalController {
    constructor(
        private readonly platformService: PlatformService,
        private readonly modalController: ModalController,
    ) {
    }

    async getTop() {
        return await this.modalController.getTop();
    }

    async create(opts: ModalOptions) {
        const isIOS = this.platformService.isIos() && this.platformService.isCordova();

        // let animation = {};
        // if (isIOS) {
        //     animation = {
        //         enterAnimation: bottomModalEnterAnimation(this.platformService.getPlatform().height(), false),
        //         leaveAnimation: bottomModalLeaveAnimation(this.platformService.getPlatform().height(), false),
        //     };
        // }

        return this.modalController.create({
            ...opts,
            // swipeToClose: isIOS,
            // mode: isIOS ? 'ios' : 'md',
            // ...animation,
            mode: 'md',
        });
    }

    dismiss(data?: any, role?: string, id?: string) {
        return this.modalController.dismiss(data, role, id);
    }
}
