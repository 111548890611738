import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import {IonicModule, IonicRouteStrategy, isPlatform} from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NativeModule } from './native/native.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { OnlineInterceptor } from './core/interceptors/online.interceptor';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { appEffects, appReducers } from './store';
import { ServiceWorkerModule } from '@angular/service-worker';

import { SharedModule } from './shared/shared.module';
import { CarrotQuestService } from './core/services/carrot-quest.service';
import {animation} from "./animation/transition";

registerLocaleData(localeRu);

const ionicOptions: any = {
    backButtonText: '',
    swipeBackEnabled: isPlatform('ios'),
    inputShims: true,
    scrollAssist: false,
    navAnimation: animation,
};

export const carrotQuestServiceInit =
    (carrotQuestService: CarrotQuestService) => async (): Promise<any> => {
      carrotQuestService.init();
      return true;
    }

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(ionicOptions),
        AppRoutingModule,
        NativeModule,
        StoreModule.forRoot(appReducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot(appEffects),
        StoreRouterConnectingModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: false,
            registrationStrategy: 'registerImmediately',
        }),
        SharedModule
    ],
    providers: [
        NativeModule,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'ru' },
        {
            provide: APP_INITIALIZER,
            useFactory: carrotQuestServiceInit,
            deps: [CarrotQuestService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OnlineInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
